/**
 * Type: ページ
 * What: ふわふわシナモロール展
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Button } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import {CINNAMOROLL_HIGHLIGHTS, CINNAMOROLL_HIGHLIGHTS2, CINNAMOROLL_WORKSHOP, CINNAMOROLL_GOODS, CINNAMOROLL_GOODSSHOP, CINNAMOROLL_TICKET} from '../config/Cinnamoroll';
import ContentsListCinnamoroll from '../components/ContentsListCinnamoroll';
import PostListComponent from '../components/PostList/PostList.cinnamoroll';
import mainImg2 from '../images/cinnamoroll/kv-cinnamoroll.jpg';

const pageTitle = 'タマホーム presents ふわふわシナモロール展';
const pageDescription = 'サンリオキャラクター大賞で2年連続1位を獲得した「シナモロール」の20周年特別展をBOSS E・ZO FUKUOKAで開催！';
const pageSlug = 'cinnamoroll';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

const sliderDataobj = [
  {
    filename: mainImg2,
    noAnimation: true,
  },
];

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
  }, []);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-12 bs-4">
        <div className="kv-wrap">
          <div className='mt-3 mb-3 sp-mb0 border-box-red sub-desc'>
            <p className="text is-3 tac fwb">本イベントは終了しました。<br />たくさんのご来場ありがとうございました</p>
          </div>
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
              <p className="credit">&copy;2022 SANRIO CO., LTD. APPROVAL NO. SP620399</p>
          </div>
          <div className='container'>
            {url.ticketReady === '1' ? (
              <div
                className="btn-wrap mb-0"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <Button
                  className="btn-buy"
                  href={`${url.ticket}#/${url.uuidEvent}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  シナモロール展の<br />チケットを探す
                </Button>
              </div>
            ) : null}
            <div className="btn-group mt-5">
              <Link
                to="/news/info/20230311252/"
                className="btn-def caution-ticket-btn narrow"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <span>新型コロナウイルス感染防止の対策について</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <PostListComponent />
      <section className="section">
        <div className="container">
          <div className="inner mb0">
            <p className="has-text-align-center has-text-weight-bold sp-mb10 text is-3">サンリオキャラクター大賞で2年連続1位を獲得した<br className='pc'/>「シナモロール」の20周年特別展を<br className='sp'/>BOSS E・ZO FUKUOKAで開催！</p>
            <p className="has-text-align-center has-text-weight-bold sp-mb10 text is-3">2017年より9都市にて開催し全国を巡回している「ふわふわシナモロール展」が、<br className='pc'/>20周年バージョンにパワーアップして登場。</p>
            <p className="has-text-align-center has-text-weight-bold text is-3 pb-5 mb-5">シナモンのデザインヒストリーや貴重なラフ原画など、<br className='pc'/>シナモンの誕生のひみつが楽しめる展覧会です。</p>
            <h2 className="headline" id="area">
              <span>みどころ</span>
            </h2>
            <div className="sc-columns now-event mb-6">
              {CINNAMOROLL_HIGHLIGHTS.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col big ${categories.colorClass}`}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListCinnamoroll {...categories} />
                </div>
              ))}
            </div>
            <p className="has-text-align-center has-text-weight-bold sp-mb10 text is-3">
              シナモロール20年の歴史をたどる貴重な原画やアート作品などを展示。</p>
              <p className="has-text-align-center has-text-weight-bold mb-3 text is-3">館内は写真撮影もOK！シナモロール誕生の世界を満喫しよう！
            </p>
            <div className="sc-columns now-event mt-6">
              {CINNAMOROLL_HIGHLIGHTS2.map((categories, index) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={`col ${categories.colorClass}`}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListCinnamoroll {...categories} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline" id="area">
              <span>限定グッズ付きチケット<br />好評販売中</span>
            </h2>
            <p className="has-text-align-center has-text-weight-bold text is-3 pb-5 mb-5">
              シナモンの福岡会場限定非売品のスタンド付きアクリルマスコット（非売品）が特典グッズとなったグッズ付きチケットも数量限定で販売中。<br />アクリルマスコットは全2種類！どちらになるかは当日のくじ引きで決定！ぜひゲットしよう！
            </p>
            <div className="sc-columns now-event">
              {CINNAMOROLL_TICKET.map((categories, index) => (
                <div
                  className={`col ${categories.colorClass}`}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListCinnamoroll {...categories} />
                </div>
              ))}
            </div>
            <p className="has-text-weight-bold mt-3 text">※画像はイメージです</p>
            <p className="has-text-weight-bold mt-3 text">※限定グッズの選択はできません</p>
            <p className="has-text-weight-bold mt-3 text">※数量限定での販売です。完売次第終了となりますので、ご了承ください。</p>
            <p className="has-text-weight-bold mt-3 text is-1 mb-6">価格：大人子供一律1,800円（税込）</p>
            <div
              className="btn-wrap mb-0"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Button
                className="btn-buy"
                href="https://ticket.e-zofukuoka.com/#/order?id=EMDC3RABS1K9CPG690XPCMGD7XWLTNYF3IYKUAJTMFRCGXIMLRWMUIEQ70JED3PG"
                target="_blank"
                rel="noopener noreferrer"
              >
                グッズ付きチケット購入
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline" id="area">
              <span>ワークショップ</span>
            </h2>
            <div className="sc-columns now-event">
              {CINNAMOROLL_WORKSHOP.map((categories, index) => (
                <div
                  className={`col ${categories.colorClass}`}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListCinnamoroll {...categories} />
                </div>
              ))}
            </div>
            <p className="has-text-align-center has-text-weight-bold mt-6 text">※画像はイメージです</p>
          </div>
        </div>
      </section>
      <section className="section bg-cinnamoroll">
        <div className="container">
          <div className="inner mb0">
            <h2 className="headline" id="area">
              <span>シナモロール展<br />グッズショップオープン！</span>
            </h2>
            <p className="has-text-align-center has-text-weight-bold text is-3 pb-2 mb-2">シナモロール展・シナモロールAnniversaryショップ限定アイテム（マスコットブローチ・ヌイグルミ・シークレットピンズ・トートバック）、シナモロール20thをお祝いする商品を多数ご用意してオープン！</p>
            <p className="has-text-align-center has-text-weight-bold text is-3 pb-5 mb-5">グッズショップへの入場は無料となります。</p>
            <div className="sc-columns now-event pb-6">
              {CINNAMOROLL_GOODSSHOP.map((categories, index) => (
                <div
                  className={`col ${categories.colorClass}`}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListCinnamoroll {...categories} />
                </div>
              ))}
            </div>
            <div className="sc-columns now-event">
              {CINNAMOROLL_GOODS.map((categories, index) => (
                <div
                  className={`col ${categories.colorClass}`}
                  data-modal={index}
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <ContentsListCinnamoroll {...categories} />
                </div>
              ))}
            </div>
            <p className="has-text-weight-bold mt-6 text">※記載の価格は全て税込です</p>
            <p className="has-text-weight-bold mt-3 text">※数量限定の為、期間中でも売り切れの場合もございますのでご容赦ください。</p>
            <p className="has-text-weight-bold mt-3 text">※シークレットピンズはお一人様1会計10点まで、そのほかのアイテムに関しましてはお一人様1会計1アイテム・1キャラクターのご購入とさせていただきます。</p>
            <p className="has-text-weight-bold mt-3 text">※掲載商品以外にもさまざまなグッズを販売予定です</p>
          </div>
        </div>
      </section>
      <section className="section" id="unkoGoods">
        <div className="inner-slim">
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>入場料金</th>
                  <td>
                    <p>
                      大人（高校生以上）: 1,000円
                      <br />
                      こども（３歳以上）: 500円
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 className="title is-5">注意事項</h4>
          <article>
            <ul className="list-base">
              <li>当館ご利用の際のお怪我や損失・損害、その他トラブルなどにつきまして当館では一切責任を負いません。</li>
              <li>営業中止を除き、いかなる場合もチケットの払い戻しは行っておりません。</li>
              <li>チケットの転売は禁止しており、転売にて購入いただいたチケットではご入場頂けず、その場合も当館は一切の責任を負いません。</li>
              <li>お子様連れのお客様は、保護者様の監督責任のもと安全にお過ごしください。</li>
              <li><b>未就学児のお子様のご入場には、保護者の同伴が必要です。</b></li>
              <li>イベントスペースでの喫煙、アメ・ガムを含む飲食はお断りしております。</li>
              <li><b>会場内にトイレ・休憩室はございません</b></li>
              <li>体調不良・泥酔状態でのご入場はお断りしております。</li>
              <li>全長50cm以上の荷物は1Fもしくは3Fのコインロッカー、荷物置場をご利用ください</li>
              <li>本イベント会場内は、補助犬を含み動物を連れての入場はお断りしております。</li>
              <li>事前承諾のない商業目的の撮影や取材はお断りしております。</li>
            </ul>
            <h4 className="ttl-strip">持ち込み禁止物</h4>
            <p>
              危険物、臭気物、生花など
              <br />
              ペットボトル、水筒以外の蓋が無いドリンク類
              <br />
              その他、スタッフが危険であると判断した物品
            </p>
          </article>
          <article>
            <h4 className="ttl-strip">障がい者割引について</h4>
            <p>
              障がい者割引チケットをご購入のお客様は、該当のチケットを購入後、入場口で障がい者手帳をご提示ください。<br />
              ※身体障害者手帳、療育手帳、精神障害者手帳保健福祉手帳の3つが対象です。<br />
              ※交付を受けている方本人のみ割引適用となります。
            </p>
          </article>
        </div>
      </section>
    </Layout>
  );
};

export default Template;
