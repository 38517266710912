const SLIDER_AREA01 = [
  {
    filename: `img-cinnamoroll-1.jpg`,
  },
];
const SLIDER_AREA02 = [
  {
    filename: `img-cinnamoroll-2.jpg`,
  },
];
const SLIDER_AREA03 = [
  {
    filename: `img-cinnamoroll-3.jpg`,
  },
];
const SLIDER_AREA04 = [
  {
    filename: `img-cinnamoroll-4.jpg`,
  },
];
const SLIDER_AREA05 = [
  {
    filename: `img-cinnamoroll-5.jpg`,
  },
];
const SLIDER_WORK01 = [
  {
    filename: `img-cinnamorollWorkshop-1.jpg`,
  },
  {
    filename: `img-cinnamorollWorkshop-1-2.jpg`,
  },
];
const SLIDER_WORK02 = [
  {
    filename: `img-cinnamorollWorkshop-2.jpg`,
  },
  {
    filename: `img-cinnamorollWorkshop-2-2.jpg`,
  },
];
const SLIDER_GOODS01 = [
  {
    filename: `cinnamoroll-goods-1.jpg`,
  },
  {
    filename: `cinnamoroll-goods-2.jpg`,
  },
];
const SLIDER_GOODS02 = [
  {
    filename: `cinnamoroll-goods-3.jpg`,
  },
];
const SLIDER_GOODS03 = [
  {
    filename: `cinnamoroll-goods-4.jpg`,
  },
];
const SLIDER_GOODS04 = [
  {
    filename: `cinnamoroll-goods-5.jpg`,
  },
];
const SLIDER_GOODS05 = [
  {
    filename: `cinnamoroll-goods-6.jpg`,
  },
];
const SLIDER_GOODS06 = [
  {
    filename: `cinnamoroll-goods-7.jpg`,
  },
];

export const CINNAMOROLL_HIGHLIGHTS = [
  {
    name: 'シナモンロール見どころ１', // エリア名
    img: 'img-cinnamoroll-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_AREA01,
    descWrap: false,
  },
];
export const CINNAMOROLL_HIGHLIGHTS2 = [
  {
    name: 'シナモンロール見どころ2', // エリア名
    img: 'img-cinnamoroll-2.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_AREA02,
    descWrap: false,
  },
  {
    name: 'シナモンロール見どころ3', // エリア名
    img: 'img-cinnamoroll-3.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_AREA03,
    descWrap: false,
  },
];
export const CINNAMOROLL_WORKSHOP = [
  {
    name: 'シナモンの素焼きアートづくり', // エリア名
    ttl: 'シナモンの素焼きアートづくり', // エリア名
    desc: 'イベント限定のレアアイテム！真っ白なシナモンの素焼きアートにデコレーションして、世界でひとつのオリジナルシナモンが作れるよ。',
    price: '料金：1,500円',
    img: 'img-cinnamorollWorkshop-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: true,
    sliderImg: SLIDER_WORK01,
    descWrap: true,
    ticketUrl: 'https://ticket.e-zofukuoka.com/#/order?id=7QR7MGUO6M9K9CDWC5I1PP8UNV9M9QXFOP8KU3LPZ0QJTBIN4ZP3D7PE7L46ZLDW',
    ticketUrl2: 'https://ticket.e-zofukuoka.com/#/order?id=XZQ3GEIO23VYRPMAQIEVYN9EGCHZ5ZQKLKWUEBUUXWK558H8VGC17AC7YJ24498H',
  },
  {
    name: 'シナモロールのデジタルぬりえ', // エリア名
    ttl: 'シナモロールのデジタルぬりえ', // エリア名
    desc: '自分で塗ったシナモロールのぬりえがスクリーンで動くよ！ぬりえは缶バッジにして持って帰ることができるよ。',
    price: '料金：500円',
    img: 'img-cinnamorollWorkshop-2.jpg', // 画像名
    imgSlider: true,
    sliderImg: SLIDER_WORK02,
    descWrap: true,
    ticketUrl: 'https://ticket.e-zofukuoka.com/#/order?id=Z6QM8K13UUCTVI63P3LUGQHIDP11IN5DTQC45U55EW3CEO1F3DSIPTXU77I09K5N',
  },
];
export const CINNAMOROLL_GOODS = [
  {
    name: 'BOX入りヌイグルミ（ショッパー付）', // エリア名
    ttl: 'BOX入りヌイグルミ（ショッパー付）', // エリア名
    price: '6,380円',
    img: 'cinnamoroll-goods-1.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: true,
    sliderImg: SLIDER_GOODS01,
    descWrap: true,
  },
  {
    name: 'ヌイグルミブローチ（全10種）', // エリア名
    ttl: 'ヌイグルミブローチ（全10種）', // エリア名
    price: '各1,100円',
    img: 'cinnamoroll-goods-3.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_GOODS02,
    descWrap: true,
  },
  {
    name: 'シークレットピンズ（全10種）', // エリア名
    ttl: 'シークレットピンズ（全10種）', // エリア名
    price: '各660円',
    img: 'cinnamoroll-goods-4.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_GOODS03,
    descWrap: true,
  },
  {
    name: 'トートバッグ', // エリア名
    ttl: 'トートバッグ', // エリア名
    price: '2,420円',
    img: 'cinnamoroll-goods-5.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_GOODS04,
    descWrap: true,
  },
];

export const CINNAMOROLL_GOODSSHOP = [
  {
    name: 'グッズショップ1', // エリア名
    img: 'cinnamoroll-goods-6.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_GOODS05,
    descWrap: false,
  },
  {
    name: 'グッズショップ2', // エリア名
    img: 'cinnamoroll-goods-7.jpg', // 画像名
    imgSlider: false,
    sliderImg: SLIDER_GOODS06,
    descWrap: false,
  },
];
export const CINNAMOROLL_TICKET = [
  {
    name: 'グッズ付きチケット１', // エリア名
    img: 'img-cinnamoroll-4.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_AREA04,
    descWrap: false,
  },
  {
    name: 'グッズ付きチケット2', // エリア名
    img: 'img-cinnamoroll-5.jpg', // 画像名
    colorClass: 'floorbc-tr1', // シャドウカラー設定
    imgSlider: false,
    sliderImg: SLIDER_AREA05,
    descWrap: false,
  },
];
