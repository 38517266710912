import { Link } from 'gatsby';
import React from 'react';
import defaultImg from '../../images/default.png';
import HtmlEscape from '../../util/htmlEscape';
import TruncateFunc from '../../util/truncate';

const to = new Date(Date.now());
const Timer = (postDate) => {
  const from = new Date(postDate.replace(/\./g, '/'));
  const ms = to.getTime() - from.getTime();
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));
  return days <= 7; // 7日以内ならtrue
};

const Card = (post) => (
  <div className="card">
    <div className="card-image">
      <Link to={post.path}>
        <figure className="image">
          <img
            src={
              post.featured_media ? post.featured_media.source_url : defaultImg
            }
            alt={post.title}
          />
        </figure>
      </Link>
    </div>
    <div className="card-content">
      <div className="tags are-normal">
        {post.categories.map((item) => (
          <Link
            className="tag is-link"
            to={`/news/${item.slug}/`}
            key={item.slug}
          >
            {item.name}
          </Link>
        ))}
      </div>
      <div className="media">
        <div className="media-content">
          <p className="">
            <Link to={post.path}>
              <span
                dangerouslySetInnerHTML={{
                  __html: HtmlEscape(TruncateFunc(post.title, 40)),
                }}
              />
            </Link>
          </p>
        </div>
      </div>
      <div className="content">
        <p className="bottoms">
          <time dateTime={post.date} className="en">
            {post.date}
          </time>
          {Timer(post.date) && <span className="new">NEW</span>}
        </p>
      </div>
    </div>
  </div>
);

export default Card;
