/**
 * Type: コンポーネント
 * What: コンテンツリスト
 */
import React from 'react';
import Image from '../util/Image';
import { Button } from './Btn';
import SimpleSwiper from './Slider.fade';

export const List = (props) => (
  <div className="col-inner">
    <div className="col-image">
      <figure className="image">
        {props.imgSlider ? (
          <SimpleSwiper data={props.sliderImg} />
        ) : (
          <Image filename={props.img ? props.img : 'now-printing.jpg'} />
        )}
      </figure>
    </div>
    {props.descWrap && (
      <div className="col-box">
        <div className={`col-content ${props.tac ? 'has-text-align-center' : ''}`}>
          {props.ttl && (
            <h3 className="ttl">{props.ttl}</h3>
          )}
          {props.desc && (
            <p className="mt-1">{props.desc}</p>
          )}
          {props.price && (
            <p className="mt-1">{props.price}</p>
          )}
          {props.ticketUrl && (
            <div
              className="btn-wrap mt-5"
            >
              <Button href={props.ticketUrl} className="btn-buy" target="_blank">
                体験チケット購入
              </Button>
            </div>
          )}
          {props.ticketUrl2 && (
            <div
              className="btn-wrap mt-5"
            >
              <Button href={props.ticketUrl2} className="btn-buy" target="_blank">
                入場券付き体験チケット購入
              </Button>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);
const ContentsListCinnamoroll = (props) => (
  <>
    {props.noPointer ? (
      <div className="no-pointer">
        <List {...props} />
      </div>
    ) : (
      <div className="no-link">
        <List {...props} />
      </div>
    )}
  </>
);

export default ContentsListCinnamoroll;
